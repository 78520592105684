import React from 'react'
import Image from 'gatsby-image'
import { Section, Container, Box, Heading, Text, Grid } from 'components'
import LeadCTAButton from 'components/LeadCTAButton'

const PageTitleWithImage = ({
  title,
  titleFontSize = 5,
  description,
  gatsbyFluidImage,
  ctaText = 'Jetzt anfragen',
  leadModalType = 'teilverkauf',
  ctaButtonID,
  backgroundColor = 'white',
  shouldShowBrochureCTA = false,
  alt,
}) => {
  return (
    <Section
      spacing={['small', null, 'large']}
      bg={backgroundColor}
      py={['0', null, '80px']}
    >
      <Container>
        <Grid columns={[1, null, 2]} gap={[2, null, 5]}>
          {gatsbyFluidImage && (
            <Box
              sx={{
                borderRadius: 'large',
                overflow: 'hidden',
                boxShadow: 'sm',
                maxHeight: '310px',
                alignSelf: 'center',
              }}
            >
              <Image
                fluid={gatsbyFluidImage}
                loading="eager"
                style={{ height: '100%' }}
                {...(typeof title === 'string' && { alt: title })}
                {...(alt && { alt })}
              />
            </Box>
          )}
          <Grid columns={[1]} gap={[2, null, 4]} sx={{ alignSelf: 'start' }}>
            <Heading.H1 sx={{ color: 'darkNavy80', fontSize: titleFontSize }}>
              {title}
            </Heading.H1>
            {description && (
              <Text sx={{ color: 'darkNavy80' }}>{description}</Text>
            )}
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                flexDirection: ['column', null, 'row'],
              }}
            >
              <LeadCTAButton
                variant="v2Main1"
                ctaButtonID={ctaButtonID}
                leadModalType={leadModalType}
                py={1}
                sx={{
                  justifySelf: ['stretch', null, 'start'],
                }}
              >
                {ctaText}
              </LeadCTAButton>
              {shouldShowBrochureCTA && (
                <LeadCTAButton
                  variant="v2Main1"
                  ctaButtonID={ctaButtonID}
                  leadModalType="brochure"
                  py={1}
                  sx={{
                    justifySelf: ['stretch', null, 'start'],
                  }}
                >
                  Infopaket anfordern
                </LeadCTAButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  )
}

export default PageTitleWithImage
