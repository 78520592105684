import React from 'react'

import { Section, Container, Box, Heading, Text } from 'components'

import LeadCTAButton from 'components/LeadCTAButton'
import FeatureBoxes from 'screens/Home/components/FeatureBoxes'
import BulletPointsList from 'screens/Home/components/BulletPointsList'

const FinancialSolutions = ({
  title = 'Sie suchen Ihre optimale Finanzierungslösung?',
  subtitle = 'Wir helfen Ihnen dabei!',
  items = [],
  bulletPointsTitle = 'Dann ist Bambus die richtige Wahl',
  bulletPointsList = [],
  ctaText = 'Anfrage starten',
  leadModalType = 'teilverkauf',
  ctaButtonID,
  backgroundColor = 'colorBlue2',
}) => {
  return (
    <Section spacing="large" sx={{ backgroundColor }}>
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" sx={{ color: 'darkNavy80' }}>
            {title}
          </Heading.H2>
          {subtitle && (
            <Text
              textAlign="center"
              sx={{ color: 'darkNavy80' }}
              mt={3}
              fontSize={3}
            >
              {subtitle}
            </Text>
          )}
        </Section.Header>

        <Section.Body>
          <FeatureBoxes items={items} />
          <Box sx={{ height: 40 }} />
          <BulletPointsList
            title={bulletPointsTitle}
            items={bulletPointsList}
          />
        </Section.Body>
        <Section.Footer sx={{ textAlign: 'center' }} mt={[6]}>
          <LeadCTAButton
            leadModalType={leadModalType}
            ctaButtonID={ctaButtonID}
            variant="v2Main1"
            sx={{
              width: ['100%', 'auto'],
              maxWidth: 400,
              mx: ['auto', 0],
            }}
          >
            {ctaText}
          </LeadCTAButton>
        </Section.Footer>
      </Container>
    </Section>
  )
}

export default FinancialSolutions
