import React from 'react'

import { Section, Container, Heading, Text, Box } from 'components'

import LeadCTAButton from 'components/LeadCTAButton'

const SimpleTextBanner = ({
  titleID,
  title = '',
  subheading,
  descriptionText,
  descriptionNode,
  hideCTA = false,
  textCTA = 'Anfrage starten',
  afterCTAText,
  backgroundColor = 'white',
  leadModalType = 'standard_offer',
  signupReason,
  ctaButtonID,
  textProps = {},
}) => {
  return (
    <Section
      spacing="large"
      sx={{
        backgroundColor,
        position: 'relative',
      }}
    >
      <Container>
        <Section.Header>
          {titleID && (
            <Box
              id={titleID}
              sx={{
                position: 'relative',
                top: [-64 - 20, null, null, -88 - 35],
              }}
            />
          )}
          <Heading.H2 textAlign="center" sx={{ color: 'darkNavy80' }}>
            {title}
          </Heading.H2>
          {subheading && (
            <Text
              textAlign="center"
              sx={{ color: 'darkNavy80', fontSize: 3 }}
              mt={[3]}
            >
              {subheading}
            </Text>
          )}
        </Section.Header>

        <Section.Body
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {descriptionText && (
            <Text as="p" {...textProps}>
              {descriptionText}
            </Text>
          )}
          {descriptionNode && descriptionNode}
          {!hideCTA && (
            <>
              <LeadCTAButton
                ctaButtonID={ctaButtonID}
                leadModalType={leadModalType}
                signupReason={signupReason}
                variant="v2Main1"
                sx={{
                  width: ['100%', 'auto'],
                  maxWidth: 600,
                  mx: ['auto', 0],
                  marginTop: [3, 7],
                  textAlign: 'center',
                }}
              >
                {textCTA}
              </LeadCTAButton>
              {afterCTAText && (
                <Text
                  sx={{
                    color: 'darkNavy100',
                    marginTop: [3, 5],
                    textAlign: 'center',
                  }}
                >
                  {afterCTAText}
                </Text>
              )}
            </>
          )}
        </Section.Body>
      </Container>
    </Section>
  )
}

export default SimpleTextBanner
