import React from 'react'

import { Box, Flex, Heading, Text, Grid } from 'components'

const FeatureBoxes = ({ items = [] }) => {
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'colorBlue10',
        borderRadius: 'large',
        backgroundColor: 'white',
      }}
    >
      <Grid gap={0} columns={[1, null, 2]}>
        {items.map(({ title, description }, index) => (
          <Box
            key={description}
            sx={{
              borderRight: [null, null, index % 2 === 0 ? '1px solid' : 'none'],
              borderTop: [
                index > 0 ? '1px solid' : 'none',
                null,
                index > 1 ? '1px solid' : 'none',
              ],
              borderColor: ['colorBlue10', null, 'colorBlue10'],
            }}
          >
            <Flex
              flexDirection={['column', 'row']}
              alignItems={[null, 'center']}
              px={[5, 6]}
              py={[5, 6]}
            >
              <Box flexGrow="1">
                <Heading.H4 mb={2} color="darkNavy80">
                  {title}
                </Heading.H4>
                <Text fontSize={2}>{description}</Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

export default FeatureBoxes
