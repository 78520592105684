import React, { useState } from 'react'
import { Link as RouterLink } from 'gatsby'
import Img from 'gatsby-image'
import {
  Section,
  Container,
  Grid,
  Heading,
  Text,
  Box,
  Space,
  Link,
} from 'components'

import Article from 'screens/Guide/components/Article'

/**
 *
 * @param subheadingText - Simple text to be shown as subheading
 * @param subheadingElement - React node to be shown as subheading
 */
const PageSnippets = ({
  title = 'Bambus unterstützt Sie bei Ihrem Vorhaben',
  subheadingText,
  subheadingElement,
  snippets = [],
  backgroundColor = 'white',
}) => {
  return (
    <Section bg={backgroundColor} spacing="large">
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" color="darkNavy80">
            {title}
          </Heading.H2>
          {subheadingText && (
            <Text textAlign="center" mt={3}>
              {subheadingText}
            </Text>
          )}
          {subheadingElement && <Box mt={3}>{subheadingElement}</Box>}
        </Section.Header>

        <Section.Body>
          <Box
            sx={{
              display: 'grid',
              rowGap: 4,
              columnGap: 6,
              gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            }}
          >
            {snippets.map((snippet) => (
              <Snippet key={snippet.id} {...snippet} />
            ))}
          </Box>
        </Section.Body>
      </Container>
    </Section>
  )
}

export default PageSnippets

const Snippet = ({
  id,
  title,
  gatsbyFluidImage,
  description,
  imagePosition,
  isInline,
  ctaText = 'Mehr erfahren',
  link,
}) => {
  const [isHover, setHover] = useState(false)
  return (
    <Box
      sx={{
        position: 'relative',
        display: isInline ? [null, null, null, 'flex'] : null,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchStart={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
    >
      <Box
        sx={{
          position: 'relative',
          flexShrink: 0,
          width: '100%',
          maxWidth: isInline ? [null, null, null, 168] : null,
          mt: isInline ? [null, null, null, 2] : null,
          mb: isInline ? [4, null, null, 0] : null,
        }}
      >
        {gatsbyFluidImage && (
          <Box
            sx={{
              borderRadius: 'large',
              overflow: 'hidden',
              position: 'relative',
              mb: 3,
              pb: isInline ? ['60%', null, null, '100%'] : '60%',
              transition: 'opacity 100ms ease',

              '> *': {
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: '0',
                right: '0',
                bottom: '0',
                left: '0',
              },

              ...(isHover && {
                opacity: 0.8,
              }),
            }}
          >
            <Img
              alt={title}
              fluid={gatsbyFluidImage}
              style={{
                position: 'absolute',
              }}
              imgStyle={{
                ...(imagePosition && { objectPosition: imagePosition }),
              }}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ pl: isInline ? [null, null, null, 4] : 0 }}>
        <Heading.H4 as="h2" sx={{ mb: 3 }} color="darkNavy80">
          {title}
        </Heading.H4>

        <Text sx={{ fontSize: 2, mb: 3 }}>{description}</Text>

        <Link
          as={RouterLink}
          to={link}
          sx={{
            fontFamily: 'Kanit',
            fontWeight: '500',
            flexShrink: 0,
            display: 'inline-block',
            fontSize: 1,
            px: 4,
            py: 2,
            lineHeight: 1,
            borderRadius: '32px',
            border: '2px solid',
            borderColor: 'darkNavy100',
            color: 'darkNavy100',
            textDecoration: 'none',
            transition: 'all 100ms ease',
            position: 'static',
            textTransform: 'uppercase',

            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            },

            '&:hover': {
              bg: 'darkNavy100',
              color: 'white',
            },
          }}
        >
          {ctaText}
        </Link>
      </Box>
    </Box>
  )
}
